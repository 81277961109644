import * as Tone from 'tone'
import WebMidi from 'webmidi'

export default (handleNoteChange, handleMidiChange) => {
  if (typeof window !== 'undefined') {
    //effects bay
    const feedbackDelay = new Tone.FeedbackDelay("4n", 0.5).toDestination();
    const reverb = new Tone.JCReverb(0.93).toDestination();
    const env = new Tone.AmplitudeEnvelope({
      attack: 0.5,
      decay: 0.2,
      sustain: 1.0,
      release: 2
    }).toDestination();
    const chorus = new Tone.Chorus(4, 2.5, 0.5).toDestination().start();
    const filter = new Tone.AutoFilter(50).toDestination();
    const limiter = new Tone.Limiter(-10).toDestination();
    const vol = new Tone.Volume(-20).toDestination();
    const synth = new Tone.PolySynth({
        "volume": 0,
        "detune": 14,
        "portamento": 12,
        "envelope": {
          "attack": 6.005,
          "attackCurve": "exponential",
          "decay": 6.1,
          "decayCurve": "linear",
          "release": 4,
          "releaseCurve": "exponential",
          "sustain": 1
        },
        "oscillator": {
          "partialCount": 40,
          "partials": [
            0.8105694691387023,
            0,
            -0.0900632743487447,
            0,
            0.03242277876554809,
            0,
            -0.016542234064055146,
            0,
            0.010007030483193857,
            0,
            -0.00669892123255126,
            0,
            0.004796269048158,
            0,
            -0.0036025309739497885,
            0,
            0.0028047386475387615,
            0,
            -0.002245344789857901,
            0,
            0.0018380260071172384,
            0,
            -0.0015322674274833694,
            0,
            0.0012969111506219236,
            0,
            -0.0011118922759104286,
            0,
            0.0009638162534348421,
            0,
            -0.0008434645880735718,
            0,
            0.0007443245813945843,
            0,
            -0.000661689362562206,
            0,
            0.0005920887283701257,
            0,
            -0.0005329187831286668,
            0
          ],
          "phase": 7,
          "type": "triangle40"
        }
    }).chain(vol, limiter, reverb, Tone.Destination);

    const settings2 = {
      reverb: new Tone.JCReverb(0.5).toDestination(),
      env: new Tone.AmplitudeEnvelope({
        attack: 0.5,
        decay: 0.2,
        sustain: 1.0,
        release: 2
      }).toDestination(),
      chorus: new Tone.Chorus(4, 2.5, 0.5).toDestination().start(),
      filter: new Tone.AutoFilter(200).toDestination(),
      limiter: new Tone.Volume(-10).toDestination()
    }
    const synth2 = new Tone.Synth().chain(vol, settings2.reverb, settings2.limiter, settings2.chorus, settings2.filter, settings2.env, Tone.Destination);

    // navigator.requestMIDIAccess().then(midiAccess => {
    //   Array.from(midiAccess.inputs).forEach(input => {
    //     let deviceName = input.filter(i => i.name == "TouchMe")?.[0]?.name
    //     input[1].onmidimessage = (msg) => {
    //       if (msg.data[2] > 0) {
    //         let note = Tone.Frequency(msg.data[1], "midi").transpose(-6).toNote();
    //         synth.triggerAttackRelease(note, '8n', '+0.5');
    //         //synth2.triggerAttackRelease(note, '1n', '+0.1');
    //         if (deviceName && deviceName == "TouchMe") handleMidiChange("TouchMe")
    //         handleNoteChange(Tone.Frequency(msg.data[1], "midi")._val)
    //       }
    //     }
    //   })
    //   console.log(Array.from(midiAccess.inputs))
    // });

    WebMidi.enable(function(err) {
      if (err) console.log("An error occurred", err);
   
      WebMidi.inputs.forEach(i => i.addListener("noteon", "all", function(e) {
        synth.triggerAttackRelease(e.note.name + e.note.octave, '3n', '+0.1');
        synth2.triggerAttackRelease(e.note.name + e.note.octave, '3n', '+0.1');
        if ((e.target._midiInput.name) == "TouchMe") handleMidiChange("TouchMe")
        handleNoteChange(Tone.Frequency(e.note.number * 5, "midi")._val)
      }));
   
    });
  }
}

