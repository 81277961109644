import * as Tone from 'tone'

export default (handleNoteChange) => {
    if (typeof window !== 'undefined') {

        const reverb = new Tone.JCReverb(0.93).toDestination();
        const chorus = new Tone.Chorus(4, 2.5, 0.5).toDestination().start();
        const filter = new Tone.AutoFilter(200).toDestination();
        const limiter = new Tone.Limiter(-10).toDestination();
        const vol = new Tone.Volume(-20).toDestination();
        const synth = new Tone.PolySynth({
            "volume": 0,
            "detune": 14,
            "portamento": 12,
            "envelope": {
                "attack": 6.005,
                "attackCurve": "exponential",
                "decay": 6.1,
                "decayCurve": "linear",
                "release": 4,
                "releaseCurve": "exponential",
                "sustain": 1
            },
            "oscillator": {
                "partialCount": 40,
                "partials": [
                    0.8105694691387023,
                    0,
                    -0.0900632743487447,
                    0,
                    0.03242277876554809,
                    0,
                    -0.016542234064055146,
                    0,
                    0.010007030483193857,
                    0,
                    -0.00669892123255126,
                    0,
                    0.004796269048158,
                    0,
                    -0.0036025309739497885,
                    0,
                    0.0028047386475387615,
                    0,
                    -0.002245344789857901,
                    0,
                    0.0018380260071172384,
                    0,
                    -0.0015322674274833694,
                    0,
                    0.0012969111506219236,
                    0,
                    -0.0011118922759104286,
                    0,
                    0.0009638162534348421,
                    0,
                    -0.0008434645880735718,
                    0,
                    0.0007443245813945843,
                    0,
                    -0.000661689362562206,
                    0,
                    0.0005920887283701257,
                    0,
                    -0.0005329187831286668,
                    0
                ],
                "phase": 7,
                "type": "triangle40"
            }
        }).chain(vol, limiter, filter, reverb, Tone.Destination);

        var el = document.getElementById("all");
        el.addEventListener("click", (e) => {
            let note = Number(((e.clientX + e.clientY) / 9).toFixed(0));
            console.log(note)
            let noteFormatted = Tone.Frequency(note, "midi").transpose(-24).toNote();
            synth.triggerAttackRelease(noteFormatted, 0.3, '+0.05');
            handleNoteChange(note)
        }, false);

    }
}