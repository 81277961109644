import * as Tone from 'tone'

const keyCodes = [81, 87, 69, 82, 84, 89, 85, 73, 79, 80,
    65, 83, 68, 70, 71, 72, 74, 75, 76
]

const notes = [59, 60, 64, 67, 69, 71, 72, 66, 76, 74]
const mappedKeys = []
keyCodes.forEach(i => mappedKeys.push({ key: i, note: notes[rand(0, notes.length - 1)] }));

export default (handleNoteChange) => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
        //effects bay
        const feedbackDelay = new Tone.FeedbackDelay("4n", 0.2).toDestination();
        const reverb = new Tone.JCReverb(0.5).toDestination();
        const env = new Tone.AmplitudeEnvelope({
            attack: 0.5,
            decay: 0.2,
            sustain: 1.0,
            release: 2
        }).toDestination();
        const chorus = new Tone.Chorus(4, 2.5, 0.5).toDestination();
        const filter = new Tone.AutoFilter(300).toDestination();
        const limiter = new Tone.Limiter(-10).toDestination();
        const vol = new Tone.Volume(-20).toDestination();
        const synth = new Tone.Synth().chain(vol, reverb, filter, limiter, chorus , env, Tone.Destination);
        console.log("KEY EVENT LISTENER IS BEING ATTACHED")
        document.addEventListener("keydown", (e) => {
            if (keyCodes.includes(e.keyCode)) {
                console.log({ message: "KEYBOARD", data: e.keyCode })
                const note = Tone.Frequency(mappedKeys.filter(i => i.key === e.keyCode)[0].note, "midi").transpose(-3).toNote()
                synth.triggerAttackRelease(note, "2n", '+0.05')
                let formattedNote = mappedKeys.filter(i => i.key === e.keyCode)[0].note
                handleNoteChange(Tone.Frequency(formattedNote, "midi").transpose(-3)._val / 10)
            }
        })
    }
}



function rand(min, max) {
    return (Math.random() * (max - min) + min).toFixed(0)
}