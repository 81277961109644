import React, { useEffect } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Intro from '../components/Intro'
import { toneHelper } from '../utils'

const IndexPage = () => {

  return (
    <Layout>
      <SEO title="Love | Touchme | Playtronica " />
      <Intro />
    </Layout>
  )
}

export default IndexPage
